
import './App.css';
import FrontPage from './components/FrontPage';

function App() {

  return (
    <div className="App">
      <FrontPage />
    </div>
  );
}


export default App;
