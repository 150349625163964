
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';


const root = ReactDOM.createRoot(document.getElementById('root'));

// verifying the ReCAPTCHA Response
const sendReCAPTCHA = (value) => {
  axios.post('https://www.google.com/recaptcha/api/siteverify', {
    secret: '6Le1m6wkAAAAAO1ZIW7yldMM6QeVGPjt2aF9o4HF',   // "6LebRlckAAAAAN9prUO3439HYaK3SCLmJgNLOizq",
    response: value
  })
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.error(error);
    });
};

root.render(
  <React.StrictMode>
    <App />
    <ReCAPTCHA 
      sitekey= '6Le1m6wkAAAAAD_5SX1u_gl5YfDSYKH2L65GGMU2'  //'6LebRlckAAAAAB_4LjvGlBDV99grVdcSB2RPyl2a'
      badge='bottomright'
      onChange= {(value) => {
        console.log("ReCaptcha Value", value);
        sendReCAPTCHA(value)
      }}
      size="invisible"
    />
  </React.StrictMode>
);


