import React from "react";

function WhatsAppLink({number, text, name}) {

  // this is to create a line break

  const message = text.replace(/\n/g, "%0D%0A")
  return (
    <button className="send-whatsapp-button" onClick={() => window.open(`https://api.whatsapp.com/send?phone=+91%20${number}&text=Hello%20${name},%0A${message}`)}>
      Send WhatsApp
    </button>
  );
}

export default WhatsAppLink;